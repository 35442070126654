<template>
  <div>
    <div class="row">
      <!-- Sidebar (4 kolom) -->
      <div class="col-md-4">
        <b-card class="user-profile-card">
          <div class="profile-info">
              <!-- Foto profil -->
              <loading v-if="Loading" />

              <div v-if="!Loading" class="wanted-overlay profile-img text-center">
                <img :src="require('@/assets/images/avatars/car1.png')"  style="width: 150px; height: 150px;"  v-if="vehicle.imgurl == null || vehicle.valid_img == false">
                <img :src="vehicle.imgurl" style="width: 150px; height: 150px;" v-if="vehicle.imgurl != null && vehicle.valid_img == true">
                <div class="overlay-text" v-if="vehicle.dpk_id != null">WANTED</div>
                <br/>
                <br/>
                <b-button v-if="!Loading" size="sm" variant="primary" @click="changeProfilePhoto">Change Profile Pic</b-button> 
              </div>
              <br/>
              
              <!-- Informasi total project -->
              <div v-if="!Loading" class="row">
                  <div class="col-md-12">
                    <!-- <pre>{{vehicle}}</pre> -->
                    <b-button block variant="danger" size="sm" @click="showDPKModal" v-if="vehicle.request_release != 1 && validAddDpk"><strong> AJUKAN SEBAGAI DPK </strong></b-button>
                    <b-button block variant="warning" size="sm" v-if="vehicle.request_release == 1"><strong> Dalam Proses Penghapusan </strong></b-button>
                    <b-button block variant="success" size="sm" @click="releaseDPK" v-if="vehicle.dpk_id != null && validrequestDeleteDPK"><strong> AJUKAN PENGHAPUSAN DPK </strong></b-button>
                  </div>
              </div>
              <!-- Separator -->
              <hr>
              <!-- Profil singkat -->
              <loading v-if="Loading" />
              <div v-if="!Loading">
                <h2>Detail Kendaraan</h2>
                <p><strong>Plate :</strong> {{ vehicle.plate }}</p>
                <p><strong>modelname :</strong> {{ vehicle.modelname }}</p>
                <p><strong>Display Name:</strong> {{ vehicle.name }}</p>

                <b-button block variant="warning" size="sm" v-if="tersita && validPengeluaran" @click="viewSita"><strong style="color:black"> Keluarkan Kendaraan Tersita </strong></b-button>

                <hr/>
                <h2>Pemilik Kendaraan</h2>
                <p><strong>Name :</strong> {{ vehicle.owner_name }}</p>
                <p><strong>Gender :</strong> {{ vehicle.sex }}</p>
                <p><strong>Tinggi :</strong> {{ vehicle.height }}</p>
                <p><strong>Tanggal Lahir :</strong> {{ vehicle.tanggal_lahir }}</p>
                <h4 style="color:yellowgreen" v-if="vehicle.periode_stnk != null"><strong>STNK :</strong> {{ vehicle.periode_stnk }}</h4>
                <h4 style="color:yellowgreen" v-if="vehicle.periode_stnk != null"><strong>STNK BY:</strong> {{ vehicle.stnk_by }}</h4>
                <h4 style="color:yellow" v-if="vehicle.periode_stnk == null"><strong>STNK :</strong> Tidak Ada STNK</h4>
              </div>
          </div>
        </b-card>

      </div>


      <!-- Content (8 kolom) -->
      <div class="col-md-8">
        <b-card>
          <b-tabs>
            <b-tab title="Catatan Krimnal Kendaraan">
              <sub-crime></sub-crime>
            </b-tab>
            <b-tab title="Catatan DPK">
              <sub-dpk :plate="plate" :dpkData="dpkData"></sub-dpk>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>

    <b-modal v-model="dpkModal" centered title="Jadikan DPK" hide-footer>
      <validation-observer v-if="!Loading" ref="dpo" tag="form" @submit.prevent="updateDPO">
        <b-form @submit.prevent="updateDPO">
          <b-form-group label="Judul">
            <validation-provider #default="{ errors }" name="judul" rules="required">
              <b-form-input v-model="dpoForm.title" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Description">
            <validation-provider #default="{ errors }" name="description" rules="required">
              <b-form-textarea id="description" v-model="dpoForm.description" ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="image">
            <validation-provider #default="{ errors }" name="image" rules="">
              <b-form-input id="url image" v-model="dpoForm.image" ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Start">
            <validation-provider #default="{ errors }" name="start" rules="required">
              <b-form-datepicker id="start" v-model="dpoForm.start" ></b-form-datepicker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="End">
            <validation-provider #default="{ errors }" name="end" rules="required">
              <b-form-datepicker id="end" v-model="dpoForm.end" ></b-form-datepicker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" variant="primary">Simpan</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal v-model="releaseDPKModal" centered title="Hapus Status DPO" hide-footer>
      <loading v-if="LoadingDPO"/>
      <validation-observer v-if="!LoadingDPO" ref="dpkhapus" tag="form" @submit.prevent="submitReleaseDPK">
        <b-form @submit.prevent="submitReleaseDPK">
          <b-form-group label="Alasan Penghapusan DPK">
            <validation-provider #default="{ errors }" name="release reason" rules="required">
              <b-form-textarea id="release reason" v-model="dpoForm.release_reason" placeholder="Berikan keterangan yang jelas dan lengkap!"></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" variant="primary" v-if="!LoadingDPO">Simpan</b-button>
        </b-form>
      </validation-observer>
    </b-modal> 

    <b-modal v-model="profileModal" centered title="Ganti Foro" hide-footer>
        <validation-observer v-if="!Loading" ref="userprofilepic" tag="form" @submit.prevent="updatePP">
          <b-form @submit.prevent="updatePP">
            <b-form-group label="url image">
              <validation-provider #default="{ errors }" name="url Image" rules="required">
               <b-form-input id="url image" v-model="profilePic" ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button type="submit" variant="primary">Simpan</b-button>
          </b-form>
        </validation-observer>
      </b-modal>

    <b-modal v-model="modalSita" centered title="Keluarkan Kendaraan" hide-footer>
      <loading v-if="LoadingPengeluaran"></loading>
      <validation-observer v-if="!LoadingPengeluaran" ref="sita" tag="form" @submit.prevent="keluarkanKendaraan">
        <b-form @submit.prevent="keluarkanKendaraan">
          <b-form-group label="Disita Oleh">
            <validation-provider #default="{ errors }" name="petugas" rules="required">
              <b-form-input :disabled="true" v-model="sitaForm.officer" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Plat Nomor Kendaraan">
            <validation-provider #default="{ errors }" name="Plat No" rules="required">
              <b-form-input :disabled="true" v-model="sitaForm.plate" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Alasan Penyitaan">
            <validation-provider #default="{ errors }" name="Alasan Penyitaan" rules="required">
              <b-form-input :disabled="true" v-model="sitaForm.reason" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Pemilik Kendaraan">
            <validation-provider #default="{ errors }" name="Pemilik" rules="required">
              <b-form-input :disabled="true" v-model="sitaForm.pemilik_name" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Biaya Pengeluaran">
            <validation-provider #default="{ errors }" name="Biaya" rules="required">
              <b-form-input :disabled="true" v-model="sitaForm.price" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Dikeluarkan Oleh">
            <validation-provider #default="{ errors }" name="dikeluarkan oleh" rules="required">
              <b-form-input :disabled="true" v-model="sitaForm.dikeluarkan_oleh" locale="en"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button type="submit" variant="primary">Keluarkan</b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import interfaces from "@/templates/_interfaces";
import Multiselect from 'vue-multiselect';

// sub component
import SubCrime from './subcomponent/Crime.vue'
import SubDpk from './subcomponent/Dpk.vue'
import _interfaces from '@/templates/_interfaces';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    SubCrime,
    SubDpk
  },
  data() {
    return {
      // Daftar spesifikasi kendaraan
      plate: this.$route.params.plate,
      Loading: false,
      LoadingPengeluaran: false,
      LoadingDPO: false,
      user: [],
      vehicle: [],
      tersita: [],
      pasalOption: [],
      dpoForm:_interfaces.dpk,
      sitaForm:_interfaces.sita_form,
      dpkModal: false,
      dpkData: [],
      infoDPKModal: false,
      cekStatusDpk: [],
      profilePic: null,
      profileModal: false,
      releaseDPKModal:false,
      modalSita:false,
      validAddDpk: false,
      validrequestDeleteDPK: false,
      validPengeluaran: false,
    };
  },
  methods: {
    init(){
      this.Loading = true;
      this.plate = this.$route.params.plate;
      this.syncInformation(this.plate);
      this.showDPKList();
      this.checkValidAccessButton(1);
      this.checkValidAccessButton(3);
      this.checkValidAccessButton(5);
    },
    checkValidAccessButton(id_config){
      if(id_config == 1){
        axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
          this.validAddDpk = response.data.valid
        })
      }else if(id_config == 3){
        axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
          this.validrequestDeleteDPK = response.data.valid
        })
      }else if(id_config == 5){
        axios.post("idp/master/role-config/auth",{id:id_config}).then((response) => {
          this.validPengeluaran = response.data.valid
        })
      }
    },
    syncInformation: function(plate){
      this.Loading = true;
      axios.post("idp/vehicle/vehicledetail",{plate:plate}).then((response) => {
        this.vehicle = response.data.data
        this.tersita = response.data.tersita
      }).catch((error) => {
      }).finally(() => {
        this.Loading = false;
      });
    },
    viewSita(){
      this.modalSita = true
      this.sitaForm.officer = this.tersita.officer;
      this.sitaForm.identifier = this.tersita.identifier;
      this.sitaForm.plate = this.tersita.plate;
      this.sitaForm.pemilik_name = this.tersita.pemilik_name;
      this.sitaForm.price = this.tersita.fee;
      this.sitaForm.dikeluarkan_oleh = localStorage.getItem('user_login_name');
      this.sitaForm.reason = this.tersita.reason;
    },
    keluarkanKendaraan(){
      this.$swal.fire({
        title: 'Apakah Anda Yakin Akan Mengeluarkan Kendaraan ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        background: '#262626',
        confirmButtonText: 'Ya. Keluarkan kendaraan!',
        theme:'dark'
      }).then((result) => {
        // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
        if (result.isConfirmed) {
          this.LoadingPengeluaran = true
          axios.post("idp/vehicle/pengeluaran",this.tersita).then((response) => {
            if(response.data.status == 2000){
              this.$swal({
                title: "Success",
                icon: "question",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }else{
              this.$swal({
                title: "Gagal",
                icon: "question",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            
            }
          }).finally(() => {
            this.LoadingPengeluaran = false
            this.modalSita = false
            this.syncInformation(this.$route.params.plate)
          });
        }
      })
    },
    removeData: function(row){
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        background: '#262626',
        confirmButtonText: 'Yes, delete it!',
        theme:'dark'
      }).then((result) => {
        // Jika pengguna menekan tombol "Yes", maka lakukan sesuatu
        if (result.isConfirmed) {
          this.LoadingCrime = true
          axios.post("idp/vehicle/delete-crime",{id:row.id}).then((response) => {
          }).finally(() => {
            this.LoadingCrime = false
            this.syncInformation(this.$route.params.plate)
          });
        }
      })
    },
    showDPKModal: function(){
      this.dpkModal = true;
      this.resetForm();
    },
    updateDPO(){
      this.$refs.dpo.validate().then((success) => {
        if(success){ 
          this.$swal({
            title: "Data Akan diajukan kepada Pimpinan",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.dpoForm.plate = this.$route.params.plate;
                axios.post("idp/vehicle/dpk",this.dpoForm).then((response) => {
                  if(response.data.status == 2000){
                    this.syncInformation(this.$route.params.plate)
                  }else{
                    this.syncInformation(this.$route.params.plate)
                  }
                  this.$swal({
                    title: "Success",
                    icon: "question",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                });
                this.dpkModal = false
            } else {
              this.dpkModal = false
            }
          });
        }
      });
    },
    showDPKList(){
      axios.post("idp/user/dpk-list",{plate:this.$route.params.plate}).then((response) => {
        this.dpkData = response.data.data
      });
    },
    changeProfilePhoto(){
      this.profileModal = true;
    },
    updatePP(){
      this.$refs.userprofilepic.validate().then((success) => {
        if(success){ 
          this.Loading = true;
          axios.post("idp/vehicle/profilepic",{id:this.vehicle.id,pic:this.profilePic}).then((response) => {
          }).catch((error) => {
            if (error.status === 4000) {
              localStorage.clear();
              this.$router.push({ path: "/login" });
              ({
                title: "Login Expired",
                message: "Sesi Login Habis",
              });
            }
          }).finally(() => {
            this.Loading = false;
            this.showlicense = false;
            this.profileModal = false;
            this.syncInformation(this.vehicle.plate)
          });
        }
      });
    },
    releaseDPK(){
      this.releaseDPKModal = true;
    },
    submitReleaseDPK(){
      this.$refs.dpkhapus.validate().then((success) => {
        if(success){ 
          this.$swal({
            title: "Laporan akan diteruskan ke pimpinan",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.LoadingDPO = true
                setTimeout(() => {
                  axios.post("idp/user/dpk-request-release",{id:this.vehicle.dpk_id,release_reason: this.dpoForm.release_reason}).then((response) => {
                  });
                  this.syncInformation(this.vehicle.plate)
                  this.LoadingDPO = false
                  this.releaseDPKModal = false
      
                  this.$swal({
                    title: "Success",
                    icon: "question",
                    background: "black",
                    confirmButtonColor: "#7367f0",
                    confirmButtonText: "Close",
                  });
                }, 1000);

            } else {
              this.infoDPOModal = false
            }
          });
        }
      });
    },
    resetForm() {
      for (const i in _interfaces.dpk) {
        _interfaces.dpk[i] = null
      }
      this.dpoForm = _interfaces.dpk
      this.selectedPasal = null
    },
  },
  mounted(){
    this.init();
  }
}
</script>

<style scoped>
/* Gaya khusus untuk halaman detail kendaraan */
.vehicle-detail-page {
  padding: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.vehicle-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.vehicle-specifications {
  margin-top: 20px;
}
.input-like-div {
  border: 1px solid #babcc2; /* Atur ketebalan dan warna border sesuai kebutuhan */
  padding: .375rem .75rem; /* Atur ukuran padding sesuai kebutuhan */
  border-radius: .25rem; /* Atur radius sudut untuk membuat kotak lebih halus */
  background-color: #babcc2; /* Atur warna latar belakang sesuai kebutuhan */
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; /* Animasi saat hover */
}

.input-like-div:hover {
  border-color: #6c757d; /* Atur warna border saat hover */
}

.is-dpo {
  background-color: #ea5455;
  border-radius: 5%;
  animation: blink-animation 1s infinite alternate; /* Animasi kedipan */
}

@keyframes blink-animation {
  0% {
    background-color: #ea5455;
  }
  10% {
    background-color: transparent; /* Ganti dengan warna latar belakang asli jika diperlukan */
  }
}

@keyframes blinking {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.overlay-text {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    color: white; /* Ubah warna teks menjadi merah */
    background-color: red;
    padding: 20px 30px;
    border-radius: 5px;
    animation: blinking 1s infinite alternate; /* Terapkan animasi blinking */
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>